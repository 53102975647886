.signUpLabel {
  margin-top: 30px !important;
  font-weight: 500 !important;
  color: white !important;
  text-align: center;
}
.signUpLabel > a {
  color: #77c720;
}

.signUpLabel > a:hover {
  color: #5e8b16 !important;
}

.headline {
  font-size: 2rem !important;
  font-weight: 700 !important;
  color: #181818 !important;
  margin: 20px 0 !important;
  text-align: center;
}

.segment {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.button {
  background-color: #c13939 !important;
  color: #fff !important;
  text-transform: uppercase !important;
}

.bottomContainer {
  align-items: center !important;
}
