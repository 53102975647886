/* html {
  font-size: calc(0.5em + 1vw);
} */
body {
  background-color: #101010; /*#f5f5f5 !important;*/
  color: #eee !important;
}

input,
h1,
h2,
h3,
h4,
a,
p,
ul,
li body {
  font-family: "Montserrat", sans-serif !important;
}

.section-container {
  position: relative !important;
  padding: 10em 3em !important;
}

/* .hero-banner {
  background-image: url("/src/static/assets/img/background.jpeg") !important;
  background-size: cover !important;
  background-attachment: fixed !important;
} */

.blackbox {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

/* Semantic UI has these classes, however they're only applicable to*/
/* grids, containers, rows and columns.*/
/* plus, there isn't any `mobile hidden`, `X hidden` class.*/
/* this snippet is using the same class names and same approach*/
/* plus a bit more but to all elements.*/
/* see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="largescreen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }

  .section-container {
    padding: 5em 1em !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="largescreen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="largescreen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="largescreen"]),
  [class*="tablet only"]:not([class*="largescreen"]),
  [class*="computer only"]:not([class*="largescreen"]),
  [class*="largescreen hidden"],
  [class*="widescreen only"]:not([class*="largescreen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="largescreen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}

/* .ui.form .field .ui.input input {
  border: 0 !important;
} */

/* .shado-banner {
  -webkit-box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.35),
    0 1px 5px -2px rgba(0, 0, 0, 0.35), 0 1px 8px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.35),
    0 1px 5px -2px rgba(0, 0, 0, 0.35), 0 1px 8px -1px rgba(0, 0, 0, 0.25);
} */

/****
      *	SIXTH BOX
      **/

#simple-gradient .blue-overlay * {
  position: relative;
}

#simple-gradient .blue-overlay:before {
  /* background: linear-gradient(
    125deg,
    rgba(42, 42, 42, 0.9) -30%,
    rgba(21, 21, 21, 0.8) 40%,
    rgba(10, 10, 10, 0.6) 100%
  ); */
  background-color: rgba(10, 10, 10, 0.5) !important;
  content: "";
  display: block;
  height: 580px !important;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}

#simple-gradient .blue-overlay {
  /* background-image: url("/src/static/assets/img/background.jpeg") !important; */
  background-position: center center !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 580px !important;
  left: 0;
  position: relative;
  background-attachment: fixed !important;
}

#simple-gradient .content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  width: 90%;
}
/* @media only screen and (max-width: 849px) {
  #simple-gradient h1 {
    font-size: 3rem;
    width: 77%;
  }
} */

.right.menu > .active {
  color: #c13939 !important;
}
.ui.active.visible.fluid.dropdown.link.item > .menu > .active.item {
  color: #c13939 !important;
}

