.hero-container {
    border-bottom: 1px solid #212121;
    position: relative;
    background-size: "cover";
    overflow: "hidden";
}

  
.hero-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    background:rgba(0, 0, 0, 0.35);
    color:#fff;
}
  
  